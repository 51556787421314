import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import FloatingCTAs from "../components/FloatingCTAs/FloatingCTAs"
import SEO from "../components/SEO/seo"
import "../styles/main.scss"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"
const VideoGrid = loadable(() => import("../components/VideoGrid/VideoGrid"))
const CTALink = loadable(() => import("../components/elements/CTALink"))
const TextBlocks = loadable(() => import("../components/TextBlocks/TextBlocks"))
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const StoriesSlider = loadable(() =>
  import("../components/StoriesSlider/StoriesSlider")
)
const VideoModule = loadable(() =>
  import("../components/VideoModule/VideoModule")
)
const StatsModule = loadable(() =>
  import("../components/StatsModule/StatsModule")
)
const TeamsSlider = loadable(() =>
  import("../components/TeamsSlider/TeamsSlider")
)

const LandingTemplate = ({ data, ...props }) => {
  const pageData = data?.strapiPage
  if (!pageData) return null

  const modules = pageData?.modules
  const { title, description, ctas } = pageData.banner_section
  const meta_title = pageData?.seo?.meta_title
  const meta_description = pageData?.seo?.meta_description
  const imagetransforms = pageData.imagetransforms
  const strapi_id = pageData.strapi_id

  return (
    <motion.div
      className={clsx("landing-template", pageData.page_class)}
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      key={pageData.strapi_id}
    >
      <SEO title={meta_title} description={meta_description} />
      <BannerLanding
        title={title}
        description={description?.data?.description}
        ctas={ctas}
      />
      {modules?.map(module => (
        <>
          {module.strapi_component === "modules.video-grid" && (
            <VideoGrid
              module={module}
              imagetransforms={imagetransforms}
              strapi_id={strapi_id}
            />
          )}
          {module.strapi_component === "modules.text-blocks-module" && (
            <TextBlocks module={module} />
          )}
          {module.strapi_component === "modules.tile-block" && (
            <TileBlock
              module={module}
              imagetransforms={imagetransforms}
              strapi_id={strapi_id}
            />
          )}
          {module.strapi_component === "modules.slider" &&
            module.slider_collection === "stories" && (
              <StoriesSlider module={module} />
            )}
          {module.strapi_component === "modules.slider" &&
            module.slider_collection === "teams" && (
              <TeamsSlider module={module} />
            )}
          {module.strapi_component === "modules.video-module" && (
            <VideoModule
              module={module}
              imagetransforms={imagetransforms}
              strapi_id={strapi_id}
            />
          )}
          {module.strapi_component === "modules.stats-module" && (
            <StatsModule module={module} />
          )}
        </>
      ))}
      <FloatingCTAs>
        {ctas?.map((cta, index) => (
          <CTALink key={index} cta={cta} className={"button"} />
        ))}
      </FloatingCTAs>
    </motion.div>
  )
}

export default LandingTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiPage(strapi_id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_VIDEO_GRID {
          ...videoGridModuleFragment
        }
        ... on STRAPI__COMPONENT_MODULES_TEXT_BLOCKS_MODULE {
          ...testBlocksModuleFragment
        }
        ... on STRAPI__COMPONENT_MODULES_TILE_BLOCK {
          ...tileBlockFragment
        }
        ... on STRAPI__COMPONENT_MODULES_SLIDER {
          ...sliderComponentFragment
        }
        ... on STRAPI__COMPONENT_MODULES_VIDEO_MODULE {
          ...videoModuleFragment
        }
        ... on STRAPI__COMPONENT_MODULES_STATS_MODULE {
          ...statsComponentFragment
        }
      }
    }
  }
`
