import React from "react"
import { Container } from "react-bootstrap"
import "./FloatingCTA.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const FloatingCTAs = ({ children }) => {
  const { isMobile } = useDeviceMedia()

  if (!isMobile) return null

  return <Container className="floating-cta-section">{children}</Container>
}

export default FloatingCTAs
