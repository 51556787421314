import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { getCtaBtnClass, parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import CTALink from "../elements/CTALink"
import "./BannerLanding.scss"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const BannerLanding = ({ title, description, ctas }) => {
  const { isMobile } = useDeviceMedia()

  return (
    <div className="landing-banner-wrap">
      <Container className="landing-banner-container">
        <div className="left-section">
          <motion.h1
            className="heading"
            variants={contentVariant}
            initial="initial"
            animate="animate"
          >
            {title}
          </motion.h1>
        </div>
        <motion.div
          className="right-section"
          variants={contentVariant}
          initial="initial"
          animate={{
            ...contentVariant.animate,
            transition: {
              ...contentVariant.animate.transition,
              delay: 0.25,
            },
          }}
        >
          <div className="description">{parseHTML(description)}</div>
          {!isMobile && !_.isEmpty(ctas) && (
            <div className="cta-section">
              {ctas.map(cta => (
                <CTALink
                  cta={cta}
                  className={clsx(getCtaBtnClass(cta.cta_theme))}
                >
                  <span>{cta.label}</span>
                </CTALink>
              ))}
            </div>
          )}
        </motion.div>
      </Container>
    </div>
  )
}

export default BannerLanding
